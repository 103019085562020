import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SliderTow = ({ sectionRefs }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 813,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container" ref={sectionRefs[4]}>
      <div className="slider-tow-page mt-5">
        <h2 className="title-1 text-center">
          Your Reviews That Have Transformed Our Coursework Help UK
        </h2>
        <Slider {...settings}>
          <div>
            <div className="slider-page-box" id="reviews">
              <div className="slider-header-bar">
                <h6 className="slider-title1">Harry</h6>
                <p className="color">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </p>
              </div>

              <p className="slider-desc">
                {" "}
                The Coursework Help was excellent, offering articulate and
                insightful feedback. I saw a substantial improvement in my
                grades and performance. Outstanding service, fast delivery, and
                excellent content. Never before has my coursework looked better.
              </p>
            </div>
          </div>

          <div>
            <div className="slider-page-box">
              <div className="slider-header-bar">
                <h6 className="slider-title1">Ava</h6>
                <p className="color">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </p>
              </div>

              <p className="slider-desc">
                Coursework writers’ methodical approach transformed my
                disorganised task into a well-structured paper. I strongly
                advise everyone to opt for their service. The clarity and depth
                of the study in the assigned coursework really impressed me.
                Coursework Help UK actually is a lifesaver!
              </p>
            </div>
          </div>

          <div>
            <div className="slider-page-box">
              <div className="slider-header-bar">
                <h6 className="slider-title1">Jacob</h6>
                <p className="color">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </p>
              </div>

              <p className="slider-desc">
                Excellent assistance with my coursework. Everything was
                completed on schedule and precisely. I am hoping to utilise the
                Coursework Help in the future. The deep feedback and edits
                provided were priceless. My coursework was flawlessly polished
                to a single sentence. I am truly ecstatic about coursework help
                online UK.
              </p>
            </div>
          </div>

          <div>
            <div className="slider-page-box">
              <div className="slider-header-bar">
                <h6 className="slider-title1">Oliver</h6>
                <p className="color">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </p>
              </div>

              <p className="slider-desc">
                Coursework Help’s subject matter experts were so precise and
                excellent. Their feedback not only helped me in my engineering
                coursework but also improved my understanding of the subject.
                Timely, competent, and accurate. This is how I would describe
                the Coursework Help. My work was so much simpler and less
                stressful thanks to them.
              </p>
            </div>
          </div>

          <div>
            <div className="slider-page-box">
              <div className="slider-header-bar">
                <h6 className="slider-title1">Jack</h6>
                <p className="color">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </p>
              </div>

              <p className="slider-desc">
                My coursework utilised premium material and properly organised
                assignment. My expectations were surpassed by the help with
                coursework! A reliable and effective coursework assistant who
                produces excellent papers and meets all deadlines. Well, it's
                worth every penny!
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default SliderTow;
