
import React from 'react';
import Header from '../layout/Header';
import Footer from '../components/Footer';


function NotFoundPage() {
  return (
   <>
   <Header/>
    <div className='not-found-page'>
        <div className='inner-box'>
          <div className='not-found-page-logo'>
        <img className='logo-image' src="/assets/image/coursework-help-logo.webp" alt='Coursework Help Logo'/>
        </div>
            <h1 className='num'>404</h1>
            <p className='not-f mt-4'>NOT FOUND</p>
            <p className='text-center'>The resource requested could not be found on this server!</p>
            <p className='text-center'><button className='back-button'><a href='/' className='found-p'>Back To HomePage</a></button></p>
        </div>
    </div>
   <Footer/>
   </>
  );
}

export default NotFoundPage;
