import React, { useState } from "react";

const Faqs = ({ sectionRefs }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="faqs-section mt-5 " ref={sectionRefs[3]}>
      <div className="container">
        <div className="row ">
          <div className="col-lg-5  mt-2">
            <div className="faqs-image-box">
              <img
                className="faqs-image"
                src="/assets/image/faqs.png"
                alt="Coursework Help FAQs"
              />
            </div>

            <p className="text-center">
              {" "}
              <button className="faqs-btn">
                <a href="/contact" className="c-color">
                  Order Now
                </a>
              </button>
            </p>
          </div>

          <div className="col-lg-7  padding-top mt-2">
            <h2 className="title">Frequently Asked Questions</h2>

            <div className="accordion">
              <button
                className="accordion-header"
                onClick={() => toggleAccordion(0)}
              >
                <span className="accordion-title">
                  Is your coursework writing service UK available for all
                  academic levels?
                </span>
                <span className="accordion-icons">
                  <i
                    className={`fa ${
                      openIndex === 0 ? "fa-angle-up" : "fa-angle-down"
                    } accordion-icon`}
                  />
                </span>
              </button>
              <div
                className={`accordion-content ${openIndex === 0 ? "open" : ""}`}
              >
                Yes, our coursework writing service UK offers students help in
                undergraduate, graduate, and GCSE programs as well as A-Level
                programs. Thus, we cover a spectrum of academic levels in the
                UK.
              </div>
            </div>

            <div className="accordion">
              <button
                className="accordion-header"
                onClick={() => toggleAccordion(1)}
              >
                <span className="accordion-title">
                  Is taking help with coursework legal?
                </span>
                <span className="accordion-icons">
                  <i
                    className={`fa ${
                      openIndex === 1 ? "fa-angle-up" : "fa-angle-down"
                    } accordion-icon`}
                  />
                </span>
              </button>
              <div
                className={`accordion-content ${openIndex === 1 ? "open" : ""}`}
              >
                Our 12+ years of service in the UK is a testament to our
                legitimacy. Hence, there is no doubt that Coursework Help is a
                legal service provider.
              </div>
            </div>

            <div className="accordion">
              <button
                className="accordion-header"
                onClick={() => toggleAccordion(2)}
              >
                <span className="accordion-title">
                  Who offers the best coursework help?
                </span>
                <span className="accordion-icons">
                  <i
                    className={`fa ${
                      openIndex === 2 ? "fa-angle-up" : "fa-angle-down"
                    } accordion-icon`}
                  />
                </span>
              </button>
              <div
                className={`accordion-content ${openIndex === 2 ? "open" : ""}`}
              >
                Due to technology, many service providers have penetrated the
                academic scene. However, Coursework Help offers the best
                services because of the expertise gained over 12+ years of
                service. Hence, our service is the best in the business.
              </div>
            </div>

            <div className="accordion">
              <button
                className="accordion-header"
                onClick={() => toggleAccordion(3)}
              >
                <span className="accordion-title">
                  Will I get personalised coursework from custom coursework
                  help?
                </span>
                <span className="accordion-icons">
                  <i
                    className={`fa ${
                      openIndex === 3 ? "fa-angle-up" : "fa-angle-down"
                    } accordion-icon`}
                  />
                </span>
              </button>
              <div
                className={`accordion-content ${openIndex === 3 ? "open" : ""}`}
              >
                Yes, you will get a personalised task from custom coursework
                help. Personalisation is the need of the hour as it is the
                requirement of the institutions to follow academic instructions.
              </div>
            </div>

            <div className="accordion">
              <button
                className="accordion-header"
                onClick={() => toggleAccordion(4)}
              >
                <span className="accordion-title">
                  How does the British coursework help ensure quality in my
                  task?
                </span>
                <span className="accordion-icons">
                  <i
                    className={`fa ${
                      openIndex === 4 ? "fa-angle-up" : "fa-angle-down"
                    } accordion-icon`}
                  />
                </span>
              </button>
              <div
                className={`accordion-content ${openIndex === 4 ? "open" : ""}`}
              >
                Through the use of reliable and valuable resources, doing
                thorough research, writing coursework from scratch, and giving
                out a meaningful analysis. With these methods constantly
                applied, the British coursework help ensures quality in
                coursework.
              </div>
            </div>

            <div className="accordion">
              <button
                className="accordion-header"
                onClick={() => toggleAccordion(4)}
              >
                <span className="accordion-title">
                  What happens if I receive AI in my coursework?
                </span>
                <span className="accordion-icons">
                  <i
                    className={`fa ${
                      openIndex === 5 ? "fa-angle-up" : "fa-angle-down"
                    } accordion-icon`}
                  />
                </span>
              </button>
              <div
                className={`accordion-content ${openIndex === 5 ? "open" : ""}`}
              >
                First of all, at Coursework Help we don’t do your tasks by using
                any AI platform, website, or tool. To ensure quality, we also
                give Turnitin Report along with your coursework to maintain
                total transparency.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
