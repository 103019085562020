import React from 'react'

const MobileMenu = ({setOpenMenu,scrollToSection}) => {
  return (
   <section className='mobile-menu-section'>
    
    <div className='side-bar'>
        <div className='menu-header'>
            <a href='/'>
            <img className='menu-logo' src="/assets/image/coursework-help-logo.webp"
            alt='Coursework Help Logo'/>
          </a>
            <img className='close-menu' src="/assets/image/colse-icon.jpg"
             alt='colse-icon' onClick={()=>setOpenMenu(false)}/>
        </div>
        <div className='menu-list'>
            <ul className='menu-item'>
                <li className='menu-list-item'><a href='/#home'
                onClick={()=>scrollToSection(0)}>Home</a></li>
                <li className='menu-list-item'><a href='/#about'
                onClick={()=>scrollToSection(1)}>About</a></li>
                <li className='menu-list-item'><a href='/#quality'
                onClick={()=>scrollToSection(2)}>Quality</a></li>
                <li className='menu-list-item'><a href='/#faqs'
                onClick={()=>scrollToSection(3)}>FAQ's</a></li>
                <li className='menu-list-item'><a href='/#reviews'
                onClick={()=>scrollToSection(4)}>Reviews</a></li>
                <li className='menu-list-item'><a href='/contact'
               >Contact</a></li>
            </ul>

            <p className='text-right'><button className='order-button'>Order Now</button></p>
        </div>
    </div>
   </section>
  )
}

export default MobileMenu