import React from "react";

const TeamSection = () => {
  return (
    <section className="team-section mt-4 text-cenetr">
      <div className="container">
        <div className="team-header">
          <h2 className="blog-title ">
            We Provide City-Wise Expertise Through Our Coursework Help UK
          </h2>
        </div>

        <div className="row  p-20 mt-4">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
            <div className="user-card-box">
              <div className="user-image-box">
                <img
                  className="user-image"
                  src="/assets/image/coursework-help-team.webp"
                  alt="Coursework Help Team"
                />
              </div>
              <h3 className="our-team">Coursework Help London</h3>
              <p className="team-des">
                Coursework Help London offers students the crucial assistance
                they need to succeed in a variety of academic topics. Our
                service provides specialised support from experts who are
                familiar with the intricacies of the educational system in the
                United Kingdom
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="user-card-box">
              <div className="user-image-box">
                <img
                  className="user-image"
                  src="/assets/image/coursework-assistance.webp"
                  alt="Coursework Assistance"
                />
              </div>
              <h3 className="our-team">Coursework Help Manchester</h3>
              <p className="team-des">
                {" "}
                Are you struggling to meet coursework academic requirements?
                Guess what! Our Coursework Help Manchester is a specialist in
                writing multiple coursework for students who are constantly
                struggling with the academic demands of the institutions in
                Manchester.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="user-card-box">
              <div className="user-image-box">
                <img
                  className="user-image"
                  src="/assets/image/exam-prepration.webp"
                  alt="Exam Prepration"
                />
              </div>
              <h3 className="our-team">Coursework Help Birmingham</h3>
              <p className="team-des">
                Assignment, dissertations, exams, thesis, essay and various
                others are the forte of our Coursework Help Birmingham. We
                specialise in original writing, having valuable sources for
                research, plagiarism-free work, and client confidentiality.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
            <div className="user-card-box">
              <div className="user-image-box">
                <img
                  className="user-image"
                  src="/assets/image/proofreading-service.webp"
                  alt="Proofreading Service"
                />
              </div>
              <h3 className="our-team">Coursework Help Leeds</h3>
              <p className="team-des">
                {" "}
                Leeds Beckett University and the University of Leeds are some of
                the institutions that our clients come from. Through our
                Coursework Help Leeds, we provide personalised assistance which
                meets the requirements of the academic curriculum.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="user-card-box">
              <div className="user-image-box">
                <img
                  className="user-image"
                  src="/assets/image/dissertation-assistance.webp"
                  alt="Dissertation Assistance"
                />
              </div>
              <h3 className="our-team">Coursework Help Liverpool</h3>
              <p className="team-des">
                Catering to the Merseyside area of the UK, our Coursework Help
                Liverpool is adept at meeting academic standards. There are some
                notable institutions in Liverpool that our clients come from
                that have opted for our service.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="user-card-box">
              <div className="user-image-box">
                <img
                  className="user-image"
                  src="/assets/image/customer-support.webp"
                  alt="Customer Support"
                />
              </div>
              <h3 className="our-team">Coursework Help Brighton</h3>
              <p className="team-des">
                {" "}
                Coursework Help Brighton assists students to efficiently manage
                their workload by utilising our complete understanding of the
                curriculum and local expertise. This directly contributes to the
                student's academic achievement and confidence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
