import React  from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const SliderPage = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true, 
    centerPadding: '0',
    prevArrow: null,
    nextArrow: null, 

    responsive: [
      {
          breakpoint: 813,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          },
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          },
      },
  ],
  };
  return (
    <div className='container'>
    <div className="slider-container mt-4 slide-in-up">
      
      <h2 className='title text-center'>
      Vital Tips For Writing An Effective Coursework From Expert Coursework Help
      </h2>
      <Slider {...settings}>
        <div>
          <div className='slider-box'>
            <h4 className='slider-head'>Getting To Know The Coursework Requirements</h4>
            <p className='slider-text'>The first and foremost step in writing the coursework is not
                writing. Instead, it is understanding the coursework
                requirements. These requirements or instructions lay the
                groundwork for any coursework. Therefore, start by carefully
                reading the task brief. Be mindful of any particular queries you
                need to cover, as well as the necessary structure and deadline
                of the coursework. As it is essential, choosing the coursework
                assignment help is paramount because of the expertise we have in
                understanding the most complex assignment brief.
                </p>
          </div>
        </div>
        <div>
          <div className='slider-box'>
            <h4 className='slider-head'>Thorough Research Is Invaluable</h4>
            <p className='slider-text'>Thorough research is an invaluable asset that can literally
                enhance the quality of your coursework. However, most students
                do not have access to or don’t know valuable resources that will
                help them in their coursework. The coursework service help
                collects information from trustworthy sources such as journals,
                and prior published research. We also have our library of
                insightful sources to choose from. Also, custom coursework help
                makes a note of key ideas and relevant quotations so they fit
                your coursework.
                 </p>
               
          </div>
        </div>
        <div>
          <div className='slider-box'>
            <h4 className='slider-head'>A Strong Thesis</h4>
            <p className='slider-text'> Your primary argument should be precisely summarised in your
                thesis statement. Most of the strong coursework is built on the
                foundation of a strong thesis statement. It helps to produce
                favourable arguments and unfavourable counterarguments. It
                should be both wide enough to cover the most important facets of
                the problem and particular enough to guide your paper. The
                thesis statement, which offers a clear direction for the
                remainder of the coursework, should be done by taking the
                expertise of coursework help service.
                </p>
          </div>
        </div>
        <div>
          <div className='slider-box'>
            <h4 className='slider-head'>Proofread Your Work</h4>
            <p className='slider-text'> Proofreading is an essential step after the completion of the
                coursework. Examine your courses with a critical eye to
                determine their order. Make sure that all of the points are
                presented clearly and that the sections flow logically from one
                to the next. Connecting the points step-by-step offers the
                reader a greater insight as to what is happening in the
                coursework. Coursework help service verifies the text for
                punctuation, spelling, and grammar faults. As these seem small
                mistakes, they are proven to be hefty when the time of
                submission comes. To find and remove mistakes that can genuinely
                impact your coursework, take the expert assistance of coursework
                writers.
              </p>
          </div>
        </div>
        <div>
          <div className='slider-box'>
            <h4 className='slider-head'>Seek Feedback</h4>
            <p className='slider-text'>The final step before submission is seeking feedback. However,
                this is where most students make mistakes. Generally, students
                end up giving a draft to friends or classmates, who have limited
                or no experience in writing coursework. A bad feedback could
                lead you to destroy exceptional coursework or vice-versa. Thus,
                you will end up losing precious marks. Hence, the best
                coursework help will point out things you could have missed for
                improvement and also provide fresh viewpoints. Therefore, our
                British coursework help offers insightful feedback so that you
                can achieve the top grades that help you to perform better.
                 </p>
          </div>
        </div>
      </Slider>
    </div>
    </div>
  );
};

export default SliderPage;
