import axios from "axios";
export const sendLeads = async (formData,cb) => {
  try {
  
    await axios.post(
      "https://crm.clickinpedia.io/lead",
      {
        ...formData,
        website: "www.courseworkhelp.uk",
        mailaccesstokken: "nahev^q7e51584Q##@&85g",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    cb()
    
  } catch (e) {}
};
