import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp2'

export default function WhatApp() {

    return (
        <FloatingWhatsApp

            chatboxClassName='chatBox'
            accountName='Experts'
            avatar={'/assets/image/web-log.png'}
            statusMessage='Reply immediately in just 5s'
            darkMode={true}
            phoneNumber='447700168833'
        />
    )
}