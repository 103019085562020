import { Fragment } from "react";
import "./App.css";
import IndexPage from "./router/IndexPage";
import WhatApp from "./components/WhatsApp";

function App() {
  return (
    <Fragment>
      <IndexPage />
      <WhatApp />
    </Fragment>
  );
}

export default App;
