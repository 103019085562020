import React, {  } from 'react'

const AboutCourse = ({sectionRefs}) => {
 
    return ( 
        <section className='about-section   mt-50 '  ref={sectionRefs[1]}>
         <div className='container'>
         <div className='about-course '>
            <h2 className='header-title'>What Is Online Coursework Help?</h2>
            <p className='uk-des'>
            Online coursework help describes a variety of academic support
            services which are aimed to help students finish their coursework
            quickly and successfully. It includes a range of services such as
            writing various coursework, advice on research, tutoring, and
            editing services. The purpose of Coursework Help is to give students
            professional guidance and tools to deepen their understanding of the
            material, sharpen their writing abilities, and guarantee that their
            assignment satisfies academic requirements. Help with coursework can
            be very crucial in handling difficult assignments, meeting
            deadlines, and getting through difficult subjects.
            </p>
         </div>


         <div className='row  mt-4  '  >

         <div className='col-lg-6 image-container mt-3'>
         <img className="about-image"  src="/assets/image/about-coursework-help.webp" alt='About Coursework Help'/>
         </div>

         <div className='col-lg-6  mt-3'>
            <span className='sub-title '>About CourseWork Help UK</span>
            <h2 className='title1'>Achieve A Distinction In Coursework By Taking Our Coursework Help</h2>
            <p className='uk-des'>
            Are you struggling to do your coursework? Don't let difficult
              subjects and approaching deadlines get to you. We have expert
              Nursing Assignment Help available to assist you at every stage of
              the process. Whether you're having trouble with theoretical ideas
              or real-world applications, our skilled Coursework Writer can
              provide excellent solutions that are customised to meet your
              demands.
            </p>
            <p>
               <a href='/contact'  className='link'>Proceed To Order </a><i class="fa fa-arrow-right right-arrow"></i>
               </p>
         </div>

         </div>
         </div>
        </section>
    )
}

export default AboutCourse