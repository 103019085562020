// src/App.js
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "../home/Index";
import NotFoundPage from "../404/NotFoundPage";
import ContactPage from "../components/ContactPage";

function IndexPage() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default IndexPage;
