import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { sendLeads } from "../function/commonFunction";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Hero = ({ sectionRefs }) => {
  const recaptchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);

  const [formData, setFormData] = useState({
    email: "",
    phone: "",
  });
  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const callBack = () => {
    recaptchaRef.current.reset();
    setFormData({
        email:"",
        phone:""
    })
    alert("Form submitted successfully!")
  };

  const submitForm = async () => {
    if (!captchaToken) {
      alert("Please complete the reCaptcha");
      return false;
    }
    await sendLeads(formData,callBack);
  };

  return (
    <section className="hero-section slide-in-up " ref={sectionRefs[0]}>
      <div className="container">
        <div className="row ">
          <div className="col-lg-6 left-p">
            <div className="hero-section-1">
              <h1 className="title">
                Best Coursework Help By{" "}
                <span className="title-color">UK's Top Coursework</span> Expert
                Writers
              </h1>
              <p className="des">
                Struggling with your coursework? Get professional help from the
                best coursework experts in the UK and achieve outstanding
                academic results.
              </p>
              <button className="call-btn"><a href="https://api.whatsapp.com/send?phone=447700168833" target="_blank" className="c-color">Chat With Coursework Expert</a></button>
            </div>
          </div>

          <div className="col-lg-6 right-p left-p">
            <div className="form-section">
              <p className="form-head">Get 30% Off On Your 1st Order</p>
              <input
                className="input-name mb-3"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                type="email"
                placeholder="Email"
              />
              <PhoneInput
                country={"gb"}
                name="phone"
                inputClass="input-name-number"
                value={formData.phone}
                placeholder="Enter your number"
                onChange={(phone) => setFormData({ ...formData, phone })}
              />
              <div className="captcha-container ">
                <ReCAPTCHA
                  sitekey="6LeXfzUqAAAAALTvzcu3qLMK7jL_s_h3vpjzT4oW"
                  onChange={onCaptchaChange}
                  ref={recaptchaRef}
                  className="google-captcha"
                />
              </div>
              <button className="submit-btn " onClick={submitForm}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
