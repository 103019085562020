import React, { useRef, useState } from 'react'
import Header from '../layout/Header'
import Hero from '../components/Hero'
import AboutCourse from '../components/AboutCourse'
import BlogSection from '../components/BlogSection'
import CourseSection from '../components/CourseSection'
import TeamSection from '../components/TeamSection'
import ScrollOuter from '../components/ScrollOuter'
import SliderPage from '../components/SliderPage'
import FunFactSection from '../components/FunFactSection'
import SliderTow from '../components/SliderTow'
import Faqs from '../components/Faqs'
import Footer from '../components/Footer'
import AnimateSlider from '../components/AnimateSlider'
import MobileMenu from '../components/MobileMenu'
import HeroSlider from '../components/HeroSlider'
import HomePageSeo from '../seo/HomePageSeo'

const Index = () => {
 
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
 
  const scrollToSection = (index) => {
    const headerOffset = 100; 
    if (sectionRefs[index].current) {
      const elementPosition = sectionRefs[index].current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
  
    }
  };
  const [openMenu,setOpenMenu]=useState(false)
  return (
    <div>

     {openMenu && <MobileMenu setOpenMenu={setOpenMenu} scrollToSection={scrollToSection}/>}
     <HomePageSeo />
        <Header scrollToSection={scrollToSection} setOpenMenu={setOpenMenu} />
        <Hero sectionRefs={sectionRefs}/>
        <HeroSlider/>
        <AboutCourse sectionRefs={sectionRefs}/>
        <BlogSection sectionRefs={sectionRefs}/>
        <CourseSection/>
        <AnimateSlider/>
        <TeamSection/>
        <ScrollOuter/>
        <SliderPage/>
        <FunFactSection/>
        <SliderTow sectionRefs={sectionRefs}/>
        <Faqs sectionRefs={sectionRefs}/>
       
        <Footer/>
    </div>


  )
}

export default Index