import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const courseworkOptions = [
  {
    title: "Accounting",
    description:
      " Accounting is the process of recording, summarizing, analyzing and reporting the financial transactions of a business. It provides stakeholders with important information for decision making. Key concepts include double entry bookkeeping, financial statements such as balance sheets and income statements and compliance with generally accepted accounting principles (GAAP) or International Financial Reporting Standards (IFRS).",
  },

  {
    title: "Law",
    description:
      " Coursework Help UK creates strong legal arguments, evaluates case studies, and applies legal theory to practical situations by getting expert assistance. As personalisation is a major factor for law coursework, the coursework writing help UK will write your legal task with personalisation, so you get A+ grades.",
  },

  {
    title: "Business",
    description:
      "The Coursework Writer helps balance your academic workload in the domain of business coursework. As plagiarism is a growing factor when it comes to business coursework, it is tough for students to avoid it. Thus, using sophisticated tools, techniques, and expertise, coursework assignment help writes a 100% plagiarism-free business coursework.",
  },

  {
    title: "Economics",
    description:
      " Microeconomics and Macroeconomics, the foundation of all economic activities are explained in great detail by the Coursework Help. Understanding and writing about them is vital for quality. As we have delivered 745k+ coursework, our coursework help service is adept at delivering quality coursework by breaking down complex concepts into understandable ones.  ",
  },

  {
    title: " Finance",
    description:
      " The Coursework Writer offers the clarity and support required to succeed, whether you're working on Financial Statements, Corporate Finance, or Investing Strategies. However, the problem of originality is a constant threat which is solved easily by the best coursework help due to the 12+ years of experience in delivering finance coursework. ",
  },

  {
    title: " Marketing",
    description:
      "  Coursework Help is a specialist in customising marketing coursework in the region of the UK. Moreover, concepts like Product Concept, Social Media Marketing, Societal Concept and various others are crucial to define. As British coursework help has delivered coursework to 210k+ students, you will get exceptionally in-depth marketing coursework. ",
  },

  {
    title: " Maths",
    description:
      " Are you struggling with the arithmetic coursework? Thus, our Coursework Help UK will alleviate your stress by doing your maths coursework for you. But most students are sometimes not satisfied with the coursework. Hence, our coursework writing service in the UK is a master by getting a 99% satisfaction rate in maths coursework.   ",
  },

  {
    title: " Nursing",
    description:
      "Medical Ethics and Patient Care are some of the most vital concepts on which nursing coursework is built. Thus, these are defined easily by our Coursework Writer. Moreover, 100% plagiarism-free coursework can be easily delivered by seeking help from the coursework writers. ",
  },

  {
    title: "Programming",
    description:
      "The logic, algorithms, and structures are understood and explained with ease by our Coursework Help. Through our coursework experts, you will get a 99% satisfaction rate guaranteed. How? This is possible due to our 12+ years of experience in writing exceptional programming coursework. ",
  },

  {
    title: "Science",
    description:
      "Our Coursework Help UK delivers the most premium assistance with regard to science coursework. Also, delivering 745k+ coursework has allowed us to gain some valuable input. Therefore, to improve your coursework, get in touch with the coursework help online UK. ",
  },

  {
    title: "Statistics",
    description:
      "Is the technicality of statistics getting the best of you? Well, this is a problem which can be easily solved by getting the expertise of the Coursework Writer. Coupled with this, the challenge of meeting academic standards could be tough. Thus, catering to 210k+ students, the coursework writing help UK will give you personalised assistance.  ",
  },

  {
    title: "Music",
    description:
      "Music elements such as rhythm, melody, harmony, and sound are all combined in music, create emotions and communicate ideas. It covers different genres, styles and traditions, reflecting different cultures and historical periods. Music studies explore music theory, composition, performance and musicology, promoting creativity, expression and cultural appreciation. ",
  },
];

const AnimateSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const activeItem = sidebarRef.current.querySelector(".active");
    if (activeItem) {
      sidebarRef.current.scrollTop =
        activeItem.offsetTop -
        sidebarRef.current.offsetHeight / 2 +
        activeItem.clientHeight / 2;
    }
  }, [activeIndex]);

  const handleSelect = (index) => {
    setActiveIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (current) => setActiveIndex(current),
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <h2 className="blog-title">Coursework Help: Our Premium Service Specialises In Multiple Subjects</h2>
      <div className="container">
        <div className="container-slider-box mt-4">
          <div className="sidebar-slider" ref={sidebarRef}>
            {courseworkOptions.map((option, index) => (
              <div
                key={index}
                className={`sidebar-option ${index === activeIndex ? "active" : ""
                  }`}
                onClick={() => handleSelect(index)}
              >
                <div className="sidebar-content">
                  <div className="ServiceListicon IconOne"></div>
                  <div>
                    <h2 className={`left-heading ${index === activeIndex ? "active-heading" : ""}`}>
                      {option.title}   <p className={index === activeIndex ? "active-heading" : ""}>
                      COURSEWORK HELP
                    </p>
                    </h2>
                    
                  </div>
                  <div
                    className={`dot1 ${index === activeIndex ? "active-dot1" : ""}`}
                  ></div>
                </div>
              </div>
            ))}
          </div>
          <div className="content1">
            <Slider {...settings} ref={sliderRef} initialSlide={activeIndex}>
              {courseworkOptions.map((option, index) => (
                <div className="min-box">
                  <div key={index} className="content-slide">
                    <div className="content-header">
                      <h3 className="service-heading">
                        {option.title}   <span className="main-heading">
                          Coursework Help
                        </span>
                      </h3>
                    </div>
                    <p>{option.description}</p>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="custom-dots">
              <span className="dot" onClick={prevSlide}>
                <i class="fa fa-arrow-circle-o-left"></i>
              </span>
              <span className="dot" onClick={nextSlide}>
                <i class="fa fa-arrow-circle-o-right"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimateSlider;
