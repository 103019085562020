import React, { useState } from 'react'

const CourseSection = () => {
  const [activeIndex, setActiveIndex] = useState(0); 

  const toggleContent = (index) => {
    setActiveIndex(index);
  };



  return (
    <section className='course-section'>
      <div className='container'>
        <h2 className='assesment-title'> Who Will Write Provide You Coursework Help UK?</h2>
      <ul className='assesment-help'>
        <li onClick={()=>toggleContent(0)} className={`underline-effect ${activeIndex === 0 ? "active-head-text":""}`}>Subject Matter Experts</li>
        <li onClick={()=>toggleContent(1)} className={`underline-effect ${activeIndex === 1 ? "active-head-text":""}`}>    PhD Holders</li>
        <li onClick={()=>toggleContent(2)} className={`underline-effect ${activeIndex === 2 ? "active-head-text":""}`}> Academic Writers</li>
      </ul>
      <hr/>
     {activeIndex ===0 && <div className='assesment-help-text'>
      <p>
              <strong>  Subject Matter Experts :</strong>Our Coursework Writer includes subject matter experts who have mastery of each of their respective subjects. The subjects that come under our expertise but are not limited to Accounting, Law, Engineering, Statistics, Finance, Marketing, Maths, etc.
            </p>
      </div>}

      {activeIndex===1 &&<div className='assesment-help-text'>
        <p>
              <strong>PhD Holders :</strong> Our coursework writing service UK is also represented by PhD holders. By using their expertise, students can get unique insights that can enhance the quality of each coursework seamlessly.

            </p>
      </div>}

     {activeIndex===2 && <div className='assesment-help-text'>
      <p>
              <strong>Academic Writers :</strong>  Through our academic writers, we have a strong knowledge base that understands the intricacies of the academic curriculum thoroughly.
            </p>
      </div>}
      </div>
    </section>
  )
}

export default CourseSection