import React, { useRef, useState } from "react";
import Header from "../layout/Header";
import Footer from "./Footer";
import { sendLeads } from "../function/commonFunction";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import ContactPageSeo from "../seo/ContactPageSeo";
const ContactPage = () => {
  const recaptchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);

  const [formData, setFormData] = useState({
    email: "",
    phone: "",
  });
  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const callBack = () => {
    recaptchaRef.current.reset();
    setFormData({
      email: "",
      phone: "",
    });
    alert("Form submitted successfully!");
  };

  const submitForm = async () => {
    if (!captchaToken) {
      alert("Please complete the reCaptcha");
      return false;
    }
    await sendLeads(formData, callBack);
  };
  return (
    <div>
      <ContactPageSeo/>
      <Header />
      <div className="container mt-5">
        <div className="row contact-page ">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div>
              <h1>Get in touch With Coursework Help UK for HD Grades</h1>
              <div className=" ">
                <p>
                  🗺️ 26 Quantum, 4 Chapeltown St, Manchester M1 2BH, United
                  Kingdom
                </p>
                <p>🗺️ 16 Miami Dr, Point Cook VIC 3030, Australia</p>
                <p>
                  📧 &nbsp;
                  <a href="mailto:help@courseworkhelp.uk" className="contact-link">
                    help@courseworkhelp.uk
                  </a>
                </p>
                <p>
                  📞 &nbsp;
                  <a href="tel:+447700168833" className="contact-link">
                  +61 489921023
                  </a>
                </p>
                <p>
                  📞 &nbsp;
                  <a href="tel:+447700168833" className="contact-link">
                    +44 7700168833
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 ">
            <div className="form-section">
              <p className="form-head">Get 30% Off On Your 1st Order</p>
              <input
                className="input-name mb-3"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                type="email"
                placeholder="Email"
              />
              <PhoneInput
                country={"gb"}
                name="phone"
                inputClass="input-name-number"
                value={formData.phone}
                placeholder="Enter your number"
                onChange={(phone) => setFormData({ ...formData, phone })}
              />
              <div className="captcha-container ">
                <div className="mb-4 input-contact-number">
                  <div className="react-tel-input">
                  <ReCAPTCHA
                  sitekey="6LeXfzUqAAAAALTvzcu3qLMK7jL_s_h3vpjzT4oW"
                  onChange={onCaptchaChange}
                  ref={recaptchaRef}
                  className="google-captcha"
                />
                  </div>
                </div>
              </div>
              <button className="submit-btn " onClick={submitForm}>Submit</button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactPage;
