import React from 'react'

const BlogSection = ({sectionRefs}) => {
    return (
        <section className=' container'>
        <div className='blog-section mt-5' ref={sectionRefs[2]}>
          
            <h2 className='blog-title'> How Does Our Coursework Writer Make Students Life Easier?</h2>

            <div className='row blog-container'>
                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                   <div className='card-1'>
                   <h3 className='card-title'>Reducing Stress</h3>
                    <p className='card-des'>It can be quite stressful to fulfil deadlines and produce
                excellent coursework. This can become a hectic schedule for
                students which can elevate stress levels. Therefore, to
                alleviate stress, you can take help with coursework. After
                taking the service, your problems will be reduced and thus you
                can focus on other academic commitments.</p>

                        <p className='text-center'><button className='blog-button'>
                            <a href='/contact' className='btn-a'>Order Now</a></button></p>
                   </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                  <div className='card-2'>
                  <h3 className='card-title'>Improved Academic Performance</h3>
                    <p className='card-des pt-1'>
                    Academic performance is a major issue that students face when it
                comes to the academic curriculum. With the expertise of the
                coursework writing help UK, you are bound to get exceptional
                service that will improve not only your grades but also your
                understanding of the subjects.
                    </p>
                    <p className='text-center'><button className='blog-button1'>
                        <a href='/contact' className='btn-a'>Order Now</a></button></p>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12 mt-3'>
                   <div className='card-3'>
                   <h3 className='card-title'>Skill Development</h3>
                    <p className='card-des'>
                    Professionally prepared coursework can communicate valuable
                writing techniques, research methodologies, and organising
                strategies to students. This can assist in the process of skill
                development which is essential for future development.
                    </p>
                    <p className='text-center'><button className='blog-button2'>
                        <a href='/contact' className='btn-a'>Order Now</a></button></p>
                   </div>
                </div>
            </div>
           
        </div>
        </section>
    )
}

export default BlogSection