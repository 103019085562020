import React from 'react'
import { Helmet } from 'react-helmet'

const ContactPageSeo = () => {
  return (
    <Helmet>
        
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes"
    />
    <title>Best Coursework Help & Writing Service In UK - Contact Us</title>
    <meta
      name="description"
      content="Need coursework help in UK? Contact our coursework expert writers today for high-quality, customized coursework writing services. Success is just a click away!"
    />
    <meta name="robots" content="index, follow" />
    <meta
      name="keywords"
      content="Contact Us, Connect with Coursework Expert, Best Coursework Help - Order Now, Contact Coursework Writer, Connect with Coursework Writing Services, Contact Coursework Experts"
    />
    <link rel="canonical" href="https://www.courseworkhelp.uk/contact" />
    <meta property="og:locale" content="en_gb" />
    <meta property="og:type" content="website" />
    <meta
      property="og:title"
      content="Best Coursework Help & Writing Service In UK - Contact Us"
    />
    <meta
      property="og:description"
      content="Need coursework help in UK? Contact our coursework expert writers today for high-quality, customized coursework writing services. Success is just a click away!"
    />
    <meta property="og:url" content="https://www.courseworkhelp.uk/contact" />
    <meta property="og:site_name" content="courseworkhelp.uk" />
    <meta property="article:publisher" content="admin" />
    <meta
      property="article:modified_time"
      content="2024-09-07T11:01:28+00:00"
    />
    <meta
      property="og:image"
      content="https://www.courseworkhelp.uk/assets/image/coursework%20help@3x.png"
    />
    <meta property="og:image:width" content="979" />
    <meta property="og:image:height" content="511" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:title"
      content="Best Coursework Help & Writing Service In UK - Contact Us"
    />
    <meta
      name="twitter:description"
      content="Need coursework help in UK? Contact our coursework expert writers today for high-quality, customized coursework writing services. Success is just a click away!"
    />
    <meta name="twitter:creator" content="Admin" />
    <meta name="twitter:site" content="" />
    <meta name="author" content="Coursework Help" />
    </Helmet>
  )
}

export default ContactPageSeo