import React from "react";

const Header = ({ scrollToSection, setOpenMenu }) => {
  return (
    <section className="header-section slide-in-up">
      <div className="container ">
        <div className="header-bar">
          <a href="/">
            <img
              className="logo"
              src="/assets/image/coursework help@3x.png"
              alt="coursework help@3x"
            />
          </a>

          <ul className="list-item">
            <li className="underline-effect">
              <a href="/" onClick={() => scrollToSection(0)}>
                Home
              </a>
            </li>
            <li className="underline-effect">
              <a href="#about" onClick={() => scrollToSection(1)}>
                About
              </a>
            </li>
            <li className="underline-effect">
              <a href="#quality" onClick={() => scrollToSection(2)}>
                Quality
              </a>
            </li>
            <li className="underline-effect">
              <a href="#faqs" onClick={() => scrollToSection(3)}>
                FAQ's
              </a>
            </li>
            <li className="underline-effect">
              <a href="#reviews" onClick={() => scrollToSection(4)}>
                Reviews
              </a>
            </li>
            <li className="underline-effect">
              <a href="/contact">
                Contact
              </a>
            </li>
         
          <button className="order-now-btn"><a href="/contact" className="c-color">Order Now</a></button>
          </ul>
          <i class="fa fa-bars bar-arrow" onClick={() => setOpenMenu(true)}></i>
        </div>
      </div>
    </section>
  );
};

export default Header;
