import React from "react";

const FunFactSection = () => {
  return (
    <section className="funfact-section mt-5">
      <div className="container">
        <h2 className="text-center">
          Our 12+ Years of Achievements That Define Our Coursework Help UK
        </h2>
        <div className="funfact-section section">
          <div className="container funfact-border bg-pattern">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="row row-col-lg-4 row-col-md-2 row-col-1">
                  <div className="col  ">
                    <div className="funfact funfact-style-two ">
                      <div className="number">
                        <span className="counter">210k</span>+
                      </div>
                      <h6 className="text">
                        Satisfied Students & Still Counting
                      </h6>
                    </div>
                  </div>

                  <div className="col  ">
                    <div className="funfact funfact-style-two ">
                      <div className="number">
                        <span className="counter">745k</span>+
                      </div>
                      <h6 className="text">Coursework Completed</h6>
                    </div>
                  </div>

                  <div className="col  ">
                    <div className="funfact funfact-style-two ">
                      <div className="number">
                        <span className="counter">100</span>%
                      </div>
                      <h6 className="text">Plagiarism-Free Coursework</h6>
                    </div>
                  </div>

                  <div className="col  ">
                    <div className="funfact funfact-style-two  box-3 ">
                      <div className="number">
                        <span className="counter">99</span>%
                      </div>
                      <h6 className="text">Satisfaction Rate</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFactSection;
