import React from 'react'

const Footer = () => {
    return (
        <section className='container-fliud'>
            <div className="footer-section section ">
                <div className="">

                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 ">
                            <div className='text-center'>
                                <a href="/">
                                    <img className='footer-logo' src="/assets/image/coursework-help-logo.webp"
                                        alt="Coursework Help Logo" loading="lazy" />
                                </a>
                            </div>
                            <div className="footer-widget">
                                <div className="footer-widget-content">
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 col-12  pl-3 mt-3">
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">Important Links</h4>
                                <div className="footer-widget-content">
                                    <ul>
                                        <li><a href="/contact">Java Coursework Help</a></li>
                                        <li><a href="/contact">Accounting Coursework Help</a></li>
                                        <li><a href="/contact">Marketing Coursework Help</a></li>
                                        <li><a href="/contact">Economics Coursework Help</a></li>
                                        <li><a href="/contact">Finance Coursework Help</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div> 
                        
                        <div className="col-lg-4 col-md-12 col-sm-12  pl-3 mt-3">
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">Contact Details</h4>
                                <div className="footer-widget-content">
                                    <div className="content">
                                        <p><i class="fa fa-map-marker footer-arrow"></i>    63 quantum 4 chapeltown street <br/> manchester M1 2BH</p>
                                        <p><a href="tel:+447700168833"><i class="fa fa-mobile-phone footer-arrow"></i> +447700168833</a></p>
                                        <p><a href="mailto:help@courseworkhelp.uk"><i class="fa fa-envelope footer-arrow"></i> help@courseworkhelp.uk</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <hr/>
                 <div className='row text-center'>
                 <div className="copyright-column col-lg-12 col-md-12 col-sm-12 text-center p-2 line-height">
                        <div className="copyright">Copyright © 2012 - 2024 <a href="#">Courseworkhelp.uk</a>,
                            All Right Reserved || A Brand 
                            of <button  className='assignment-button'><a href="https://www.assignmentwriter.io/" className='anc'>Assignment Writer</a></button>
                             </div>
                    </div>
                 </div>

                </div>
            </div>
        </section>
    )
}

export default Footer